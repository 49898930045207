import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ModalOption {
  header: string;
  closeButton: boolean;
  content: string;
  actions: ModalOptionsActions[];
}

export interface ModalOptionsActions {
  label: string;
  type: 1 | 2 | 3;
  handler?: () => Promise<void>;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalOpen = new BehaviorSubject<boolean>(false);
  private modalData = new BehaviorSubject<ModalOption | null>(null);
  private loading = new BehaviorSubject<boolean>(false);

  get status$(): Observable<boolean> {
    return this.modalOpen.asObservable();
  }

  get data$(): Observable<ModalOption | null> {
    return this.modalData.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this.loading.asObservable();
  }

  open(options: ModalOption): void {
    this.modalData.next(options);
    this.modalOpen.next(true);
  }

  close(): void {
    this.modalData.next(null);
    this.modalOpen.next(false);
  }

  action(handler?: () => Promise<void>): void {
    this.loading.next(true);
    const effectiveHandler = handler || this.emptyHandler;
    effectiveHandler().finally(() => {
      this.loading.next(false);
      this.close();
    });
  }

  private emptyHandler(): Promise<void> {
    return Promise.resolve();
  }
}
